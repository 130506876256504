import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Input } from "antd";
import Loading from "../Loading";
import { getCampaignGroupedInsertionOrder, setCampaignInsertionOrder } from "../../appRedux/actions/Filters";

const FilterCampaignInsertionOrderTable = () => {
  const dispatch = useDispatch();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dataSource, setDataSource] = useState();
  const [value, setValue] = useState("");

  const columns = [
    {
      title: "IO name",
      dataIndex: "name",
    },
    {
      title: "IO Performance Goal",
      dataIndex: "performance_goal",
    },
    {
      title: "IO Budget",
      dataIndex: "budget",
      width: "13%",
      render: (_, { budget }) => (
        <>
          {budget.map((value) => {
            return <div>{value}</div>;
          })}
        </>
      ),
    },

    {
      title: "Campaign budget ID",
      dataIndex: "campaign_budget_id",
      width: "13%",
      render: (_, { campaign_budget_id }) => (
        <>
          <div>{campaign_budget_id}</div>
        </>
      ),
    },

    {
      title: "Description",
      dataIndex: "description",
      width: "13%",
      render: (_, { description }) => (
        <>
          <div>{description}</div>
        </>
      ),
    },
    {
      title: "Start date",
      dataIndex: "start_date",
      width: "13%",
      render: (_, { start_date }) => (
        <>
          {start_date.map((value) => {
            return <div>{value}</div>;
          })}
        </>
      ),
    },
    {
      title: "End date",
      dataIndex: "end_date",
      width: "13%",
      render: (_, { end_date }) => (
        <>
          {end_date.map((value) => {
            return <div>{value}</div>;
          })}
        </>
      ),
    },
  ];

  const campaignInsertionOrder = useSelector(({ campaignFiltersData }) => campaignFiltersData.campaignInsertionOrder);

  const onDatasetChange = (value) => {
    dispatch(setCampaignInsertionOrder(value));
  };

  const getCurrentFilter = () => {
    return campaignInsertionOrder.current ? campaignInsertionOrder.current.map((item) => item.key) : false;
  };

  useEffect(() => {
    if (!campaignInsertionOrder.data) {
      dispatch(getCampaignGroupedInsertionOrder());
    }
  }, []);

  useEffect(() => {
    setDataSource(campaignInsertionOrder.data);
  }, [campaignInsertionOrder]);

  const onSelectChange = (selectedRowKeys) => {
    onDatasetChange(selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
  };

  const FilterByNameInput = (
    <Input
      placeholder="Search Name"
      value={value}
      onChange={(e) => {
        const currValue = e.target.value;
        setValue(currValue);
        const filteredData = campaignInsertionOrder.data.filter((entry) => entry.name.includes(currValue));
        setDataSource(filteredData);
      }}
    />
  );

  return (
    <>
      {dataSource ? (
        <>
          <Input
            placeholder="Search Name"
            value={value}
            className="gx-mb-2"
            onChange={(e) => {
              const currValue = e.target.value;
              setValue(currValue);
              const filteredData = campaignInsertionOrder.data.filter((entry) =>
                entry.name.toLowerCase().includes(currValue.toLowerCase())
              );
              setDataSource(filteredData);
            }}
          />
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            scroll={{ y: 300 }}
          />
        </>
      ) : campaignInsertionOrder.loading ? (
        <Loading className="gx-my-2" />
      ) : (
        <div className="gx-my-2">No data</div>
      )}
    </>
  );
};

export default FilterCampaignInsertionOrderTable;
